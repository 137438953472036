import { firebase } from './../Firebase';

export const SignOut = () => {
	firebase
		.auth()
		.signOut()
		.then(function () {
			localStorage.setItem('token', '');
			console.log('Sign-out successful');
			window.open('/', '_self');
		})
		.catch(function (error) {
		});
};
