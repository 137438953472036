import React from 'react';
import { withStyles, CssBaseline } from '@material-ui/core';

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	container_al: {
		marginTop: theme.spacing(12),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
		marginBottom: theme.spacing(6),
		minHeight: (window.innerHeight * 43.7) / 100,
	},
});

class NotFound extends React.Component {
	render() {
		const { classes } = this.props;
		return (
			<>
				<CssBaseline />
				<div className={classes.appbar}></div>
				<h1 className={classes.container_al}>
					Login or Signup <br></br>To Continue
				</h1>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(NotFound);
