import React from 'react';
import { FormControl, CircularProgress, InputLabel, Select, CssBaseline, Typography, Button, TextField, Container, withStyles } from '@material-ui/core';
import { firebase } from './../Firebase';
import { green } from '@material-ui/core/colors';
import Snackbar from './../Components/Snackbar';
import jwt_decode from 'jwt-decode';

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	logo: {
		textAlign: 'center',
		width: '50%',
	},
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		position: 'relative',
	},
	container: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(5),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(10) },
		minHeight: (window.innerHeight * 43.7) / 100,
	},
	mainHeader: {
		fontWeight: 'bold',
		fontSize: theme.spacing(6),
	},
});

class Signup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			password: '',
			confirmPassword: '',
			role: 'Campaigner',
			post: '',
			roll: '',
			mobileNumber: '',
			disabled: false,
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	signup = (e) => {
		e.preventDefault();

		const signupComponent = this;

		const { name, email, password, confirmPassword, role, post, mobileNumber } = signupComponent.state;
		email.toLowerCase();

		let date1 = new Date();
		let date2 = new Date('January 08, 2024 00:00:00');
		let date3 = new Date('January 09, 2024 23:30:00');

		if (date1 < date2 && email !== 'ashishg20@iitk.ac.in') {
			signupComponent.renderSnackbar('Nominations filling starts at 06 January, 12 AM!', 'error');
			return;
		}

		if (date1 > date3 && email !== 'ashishg20@iitk.ac.in') {
			signupComponent.renderSnackbar('Nominations filling has ended!', 'error');
			return;
		}

		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (name !== '' && email !== '' && password !== '' && confirmPassword !== '' && role !== '' && post!=='') {
			if (password === confirmPassword) {
				if (role === 'Campaigner') {
					if (email.includes('@iitk.ac.in') && re.test(String(email).toLowerCase())) {
						if (mobileNumber.length === 10) {
							signupComponent.setState({
								disabled: !signupComponent.state.disabled,
							});

							firebase
								.auth()
								.createUserWithEmailAndPassword(email, password)
								.then((userCredential) => {
									var user = userCredential.user;

									user.updateProfile({
										displayName: name,
									})
										.then(() => {
											console.log('Username updated!');

											user.sendEmailVerification()
												.then(function () {
													console.log('Email Sent!');

													signupComponent.addDataToFirestore();
												})
												.catch(function (error) {
													alert(error);
													signupComponent.setState({
														disabled: !signupComponent.state.disabled,
													});
												});
										})
										.catch((error) => {
											console.log(error, 'name');
										});

									firebase
										.auth()
										.signOut()
										.then(() => {
											console.log('Signed Out');
										})
										.catch((error) => {
											console.log(error, 'auth');
											signupComponent.setState({
												disabled: !signupComponent.state.disabled,
											});
										});
								})
								.catch((error) => {
									console.log(error, 'overall');
									signupComponent.renderSnackbar(error.message, 'error');
									signupComponent.setState({
										disabled: !signupComponent.state.disabled,
									});
								});
						} else {
							signupComponent.renderSnackbar('Please enter a valid mobile number!', 'error');
						}
					} else {
						signupComponent.renderSnackbar('Enter IITK Email Address!', 'info');
					}
				} else {
					signupComponent.renderSnackbar('Please fill all the fields!', 'error');
				}
			} else {
				signupComponent.renderSnackbar('Passwords do not match!', 'error');
			}
		} else {
			signupComponent.renderSnackbar('Please fill all the fields!', 'error');
		}
	};

	addDataToFirestore = () => {
		const signupComponent = this;

		const { name, email, role, post, mobileNumber, roll } = signupComponent.state;
		email.toLowerCase();

		firebase
			.firestore()
			.collection('users')
			.add({
				name: name,
				email: email,
				role: role,
				post: post,
				mobileNumber: mobileNumber,
				roll: roll,
				isAdmin: false,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then((docRef) => {
				signupComponent.renderSnackbar('User created successfully, to verify your account check your inbox!', 'success');

				signupComponent.setState({
					name: '',
					email: '',
					password: '',
					confirmPassword: '',
					role: '',
					post: '',
					mobileNumber: '',
					roll: '',
					disabled: !signupComponent.state.disabled,
				});
			})
			.catch((error) => {
				console.error('Error adding document: ', error, 'firestore');
				signupComponent.setState({
					disabled: !signupComponent.state.disabled,
				});
			});
	};

	componentDidMount() {
		if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
		} else {
			var decoded = jwt_decode(localStorage.getItem('token'));
			var dateNow = new Date();

			if (decoded.exp < Math.floor(dateNow.getTime() / 1000)) {
				localStorage.removeItem('token');
				window.open('/login', '_self');
			} else {
				window.open('/profile', '_self');
			}
		}
	}

	render() {
		const { classes } = this.props;

		const postsAvailaible =  [
			['Campaigner of Vikash Kumar for General Secretary (PG), Academics and Career Council', 'Campaigner of Vikash Kumar for General Secretary (PG), Academics and Career Council'],
		['Campaigner of Yogesh Ram Gopal Singh for General Secretary (PG), Academics and Career Council', 'Campaigner of Yogesh Ram Gopal Singh for General Secretary (PG), Academics and Career Council'],
		['Campaigner of Sushant Faujdar for General Secretary (UG), Academics and Career Council', 'Campaigner of Sushant Faujdar for General Secretary (UG), Academics and Career Council'],
		['Campaigner of Utkarsh Gupta for General Secretary (UG), Academics and Career Council', 'Campaigner of Utkarsh Gupta for General Secretary (UG), Academics and Career Council'],
		['Campaigner of Vaibhav Kadiyan for General Secretary, Games and Sports Council', 'Campaigner of Vaibhav Kadiyan for General Secretary, Games and Sports Council'],
		['Campaigner of Lakshay Gupta for General Secretary, Media and Cultural Council', 'Campaigner of Lakshay Gupta for General Secretary, Media and Cultural Council'],
		['Campaigner of Hardik Agrawal for General Secretary, Science and Technology Council', 'Campaigner of Hardik Agrawal for General Secretary, Science and Technology Council'],
		['Campaigner of Dhruv Misra for President, Students\' Gymkhana', 'Campaigner of Dhruv Misra for President, Students\' Gymkhana'],
		['Campaigner of Jayant Nagar for President, Students\' Gymkhana', 'Campaigner of Jayant Nagar for President, Students\' Gymkhana'],
		['Campaigner of Aditya Kushwaha for Senator BT/BS Y20', 'Campaigner of Aditya Kushwaha for Senator BT/BS Y20'],
		['Campaigner of Aditya Subramanian for Senator BT/BS Y20', 'Campaigner of Aditya Subramanian for Senator BT/BS Y20'],
		['Campaigner of Akshunya Vijayvargiya for Senator BT/BS Y20', 'Campaigner of Akshunya Vijayvargiya for Senator BT/BS Y20'],
		['Campaigner of Anjanesh Rakesh for Senator BT/BS Y20', 'Campaigner of Anjanesh Rakesh for Senator BT/BS Y20'],
		['Campaigner of Janhvi Rochwani for Senator BT/BS Y20', 'Campaigner of Janhvi Rochwani for Senator BT/BS Y20'],
		['Campaigner of Paramveer Chaudhary for Senator BT/BS Y20', 'Campaigner of Paramveer Chaudhary for Senator BT/BS Y20'],
		['Campaigner of Pranjal Sharma for Senator BT/BS Y20', 'Campaigner of Pranjal Sharma for Senator BT/BS Y20'],
		['Campaigner of Sahil Aggarwal for Senator BT/BS Y20', 'Campaigner of Sahil Aggarwal for Senator BT/BS Y20'],
		['Campaigner of Shashwat for Senator BT/BS Y20', 'Campaigner of Shashwat for Senator BT/BS Y20'],
		['Campaigner of Sheshank for Senator BT/BS Y20', 'Campaigner of Sheshank for Senator BT/BS Y20'],
		['Campaigner of Abhiraj Akhouri for Senator BT/BS Y21', 'Campaigner of Abhiraj Akhouri for Senator BT/BS Y21'],
		['Campaigner of Agnishwar Mukherjee for Senator BT/BS Y21', 'Campaigner of Agnishwar Mukherjee for Senator BT/BS Y21'],
		['Campaigner of Dinesh Choudhary for Senator BT/BS Y21', 'Campaigner of Dinesh Choudhary for Senator BT/BS Y21'],
		['Campaigner of Gagan Kumar for Senator BT/BS Y21', 'Campaigner of Gagan Kumar for Senator BT/BS Y21'],
		['Campaigner of Prassun Duggar for Senator BT/BS Y21', 'Campaigner of Prassun Duggar for Senator BT/BS Y21'],
		['Campaigner of Ritam Acharya for Senator BT/BS Y21', 'Campaigner of Ritam Acharya for Senator BT/BS Y21'],
		['Campaigner of Siddhant Jakhotiya for Senator BT/BS Y21', 'Campaigner of Siddhant Jakhotiya for Senator BT/BS Y21'],
		['Campaigner of Sourav Sharma for Senator BT/BS Y21', 'Campaigner of Sourav Sharma for Senator BT/BS Y21'],
		['Campaigner of Srijan Kumar for Senator BT/BS Y21', 'Campaigner of Srijan Kumar for Senator BT/BS Y21'],
		['Campaigner of Aditya Raj Mishra for Senator BT/BS Y22', 'Campaigner of Aditya Raj Mishra for Senator BT/BS Y22'],
		['Campaigner of Anukalp Rai for Senator BT/BS Y22', 'Campaigner of Anukalp Rai for Senator BT/BS Y22'],
		['Campaigner of Chhayank Garg for Senator BT/BS Y22', 'Campaigner of Chhayank Garg for Senator BT/BS Y22'],
		['Campaigner of Chiranshu Kataria for Senator BT/BS Y22', 'Campaigner of Chiranshu Kataria for Senator BT/BS Y22'],
		['Campaigner of Naman Tyagi for Senator BT/BS Y22', 'Campaigner of Naman Tyagi for Senator BT/BS Y22'],
		['Campaigner of Nikant Yadav for Senator BT/BS Y22', 'Campaigner of Nikant Yadav for Senator BT/BS Y22'],
		['Campaigner of Pratham Sharma for Senator BT/BS Y22', 'Campaigner of Pratham Sharma for Senator BT/BS Y22'],
		['Campaigner of Ram Ratan Aggarwal for Senator BT/BS Y22', 'Campaigner of Ram Ratan Aggarwal for Senator BT/BS Y22'],
		['Campaigner of Shruti Ramchandra Dalvi for Senator BT/BS Y22', 'Campaigner of Shruti Ramchandra Dalvi for Senator BT/BS Y22'],
		['Campaigner of Ujjawal Agrawal for Senator BT/BS Y22', 'Campaigner of Ujjawal Agrawal for Senator BT/BS Y22'],
		['Campaigner of Yash Giri for Senator BT/BS Y22', 'Campaigner of Yash Giri for Senator BT/BS Y22'],
		['Campaigner of Aditya Sati for Senator BT/BS Y23', 'Campaigner of Aditya Sati for Senator BT/BS Y23'],
		['Campaigner of Anand Mahala for Senator BT/BS Y23', 'Campaigner of Anand Mahala for Senator BT/BS Y23'],
		['Campaigner of Arihant Satpathy for Senator BT/BS Y23', 'Campaigner of Arihant Satpathy for Senator BT/BS Y23'],
		['Campaigner of Divi Pothukuchi for Senator BT/BS Y23', 'Campaigner of Divi Pothukuchi for Senator BT/BS Y23'],
		['Campaigner of Divyaman Pal for Senator BT/BS Y23', 'Campaigner of Divyaman Pal for Senator BT/BS Y23'],
		['Campaigner of Harshul Kamboj for Senator BT/BS Y23', 'Campaigner of Harshul Kamboj for Senator BT/BS Y23'],
		['Campaigner of Jaiveer Deepak Sabharwal for Senator BT/BS Y23', 'Campaigner of Jaiveer Deepak Sabharwal for Senator BT/BS Y23'],
		['Campaigner of Prasun Shrivastav for Senator BT/BS Y23', 'Campaigner of Prasun Shrivastav for Senator BT/BS Y23'],
		['Campaigner of Ravi Arora for Senator BT/BS Y23', 'Campaigner of Ravi Arora for Senator BT/BS Y23'],
		['Campaigner of Shivesh Shukla for Senator BT/BS Y23', 'Campaigner of Shivesh Shukla for Senator BT/BS Y23'],
		['Campaigner of Shravan Agrawal for Senator BT/BS Y23', 'Campaigner of Shravan Agrawal for Senator BT/BS Y23'],
		['Campaigner of Vedant Shekar Tiwari for Senator BT/BS Y23', 'Campaigner of Vedant Shekar Tiwari for Senator BT/BS Y23'],
		['Campaigner of Azhar Tanweer for Senator BT/BS-MT/MS/MBA/MDes Y19', 'Campaigner of Azhar Tanweer for Senator BT/BS-MT/MS/MBA/MDes Y19'],
		['Campaigner of Kinshuk Siyol for Senator BT/BS-MT/MS/MBA/MDes Y20', 'Campaigner of Kinshuk Siyol for Senator BT/BS-MT/MS/MBA/MDes Y20'],
		['Campaigner of Siddharth Govil for Senator BT/BS-MT/MS/MBA/MDes Y20', 'Campaigner of Siddharth Govil for Senator BT/BS-MT/MS/MBA/MDes Y20'],
		['Campaigner of Pradhuman Singh for Senator M.Tech (2 Year) Y22', 'Campaigner of Pradhuman Singh for Senator M.Tech (2 Year) Y22'],
		['Campaigner of Snehal Sahu for Senator M.Tech (2 Year) Y22', 'Campaigner of Snehal Sahu for Senator M.Tech (2 Year) Y22'],
		['Campaigner of Suraj Kumar Mandal for Senator M.Tech (2 Year) Y22', 'Campaigner of Suraj Kumar Mandal for Senator M.Tech (2 Year) Y22'],
		['Campaigner of Vipul Neharwal for Senator M.Tech (2 Year) Y22', 'Campaigner of Vipul Neharwal for Senator M.Tech (2 Year) Y22'],
		['Campaigner of Amol Singh for Senator M.Tech (2 Year) Y23', 'Campaigner of Amol Singh for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Anshul Goyal for Senator M.Tech (2 Year) Y23', 'Campaigner of Anshul Goyal for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Bhavna for Senator M.Tech (2 Year) Y23', 'Campaigner of Bhavna for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Rahul Poddar for Senator M.Tech (2 Year) Y23', 'Campaigner of Rahul Poddar for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Shivesh Mishra for Senator M.Tech (2 Year) Y23', 'Campaigner of Shivesh Mishra for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Shubham Chaudhary for Senator M.Tech (2 Year) Y23', 'Campaigner of Shubham Chaudhary for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Shubham Mansingh Pawar for Senator M.Tech (2 Year) Y23', 'Campaigner of Shubham Mansingh Pawar for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Sourabh Shyamal for Senator M.Tech (2 Year) Y23', 'Campaigner of Sourabh Shyamal for Senator M.Tech (2 Year) Y23'],
		['Campaigner of Harsh Pandey for Senator MBA, MDes and other two-year programmes Y22', 'Campaigner of Harsh Pandey for Senator MBA, MDes and other two-year programmes Y22'],
		['Campaigner of Himanshu Rajput for Senator MBA, MDes and other two-year programmes Y23', 'Campaigner of Himanshu Rajput for Senator MBA, MDes and other two-year programmes Y23'],
		['Campaigner of Suraj Kumar Dineshchandra for Senator MBA, MDes and other two-year programmes Y23', 'Campaigner of Suraj Kumar Dineshchandra for Senator MBA, MDes and other two-year programmes Y23'],
		['Campaigner of Vikram Kumar Kaware for Senator MSc Y22 (including MSc-PhD Y22)', 'Campaigner of Vikram Kumar Kaware for Senator MSc Y22 (including MSc-PhD Y22)'],
		['Campaigner of Madhav Madhukar for Senator MSc Y23 (including MSc-PhD Y23)', 'Campaigner of Madhav Madhukar for Senator MSc Y23 (including MSc-PhD Y23)'],
		['Campaigner of Manan Arora for Senator MSc Y23 (including MSc-PhD Y23)', 'Campaigner of Manan Arora for Senator MSc Y23 (including MSc-PhD Y23)'],
		['Campaigner of Pranav Kumar Anupam for Senator MSc Y23 (including MSc-PhD Y23)', 'Campaigner of Pranav Kumar Anupam for Senator MSc Y23 (including MSc-PhD Y23)'],
		['Campaigner of Rayyan Ahmed Khan for Senator MSc Y23 (including MSc-PhD Y23)', 'Campaigner of Rayyan Ahmed Khan for Senator MSc Y23 (including MSc-PhD Y23)'],
		['Campaigner of Indrajeet Date for Senator MSR', 'Campaigner of Indrajeet Date for Senator MSR'],
		['Campaigner of Shubham Madhav Patil for Senator MSR', 'Campaigner of Shubham Madhav Patil for Senator MSR'],
		['Campaigner of Alok Jaiswal for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Alok Jaiswal for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Anoop Rathore for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Anoop Rathore for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Govinda Bakna for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Govinda Bakna for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Keesanth Singh C for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Keesanth Singh C for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Md Faiz Akhtar for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Md Faiz Akhtar for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Rahul Verma for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Rahul Verma for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Satyam Kumar for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Satyam Kumar for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Shubham Saxena for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Shubham Saxena for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Shubhendu Singh for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Shubhendu Singh for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Vijaya for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)', 'Campaigner of Vijaya for Senator PhD Y21 and earlier batches (including MSc-PhD Y19)'],
		['Campaigner of Baidurya Mukerjee for Senator PhD Y22 (including MSc-PhD Y20)', 'Campaigner of Baidurya Mukerjee for Senator PhD Y22 (including MSc-PhD Y20)'],
		['Campaigner of Hidangnayum Bebina Devi for Senator PhD Y22 (including MSc-PhD Y20)', 'Campaigner of Hidangnayum Bebina Devi for Senator PhD Y22 (including MSc-PhD Y20)'],
		['Campaigner of Sumit Gusain for Senator PhD Y22 (including MSc-PhD Y20)', 'Campaigner of Sumit Gusain for Senator PhD Y22 (including MSc-PhD Y20)'],
		['Campaigner of Yehya Rasool for Senator PhD Y22 (including MSc-PhD Y20)', 'Campaigner of Yehya Rasool for Senator PhD Y22 (including MSc-PhD Y20)'],
		['Campaigner of Arvind Marandi for Senator PhD Y23 (including MSc-PhD Y21)', 'Campaigner of Arvind Marandi for Senator PhD Y23 (including MSc-PhD Y21)'],
		['Campaigner of Brajesh Kumar for Senator PhD Y23 (including MSc-PhD Y21)', 'Campaigner of Brajesh Kumar for Senator PhD Y23 (including MSc-PhD Y21)'],
		['Campaigner of Garima Maurya for Senator PhD Y23 (including MSc-PhD Y21)', 'Campaigner of Garima Maurya for Senator PhD Y23 (including MSc-PhD Y21)'],
		['Campaigner of Pratiksha Vasant Jadhav for Senator PhD Y23 (including MSc-PhD Y21)', 'Campaigner of Pratiksha Vasant Jadhav for Senator PhD Y23 (including MSc-PhD Y21)'],
		['Campaigner of Ramswroop Ishram for Senator PhD Y23 (including MSc-PhD Y21)', 'Campaigner of Ramswroop Ishram for Senator PhD Y23 (including MSc-PhD Y21)'],
		['Campaigner of Shivam Tanwar for Senator PhD Y23 (including MSc-PhD Y21)', 'Campaigner of Shivam Tanwar for Senator PhD Y23 (including MSc-PhD Y21)']
		];

		const candidateDetails = [
			{
				label: 'Full Name',
				name: 'name',
				type: 'text',
				required: true,
				value: this.state.name,
				placeholder: 'Enter your full name',
			},
			{
				label: 'Email-ID',
				name: 'email',
				type: 'email',
				required: true,
				value: this.state.email,
				placeholder: 'Enter your IITK email address',
			},
			{
				label: 'Password',
				name: 'password',
				type: 'password',
				required: true,
				value: this.state.password,
				placeholder: 'Enter your password',
			},
			{
				label: 'Confirm Password',
				name: 'confirmPassword',
				type: 'password',
				required: true,
				value: this.state.confirmPassword,
				placeholder: 'Enter your password once again',
			},
			{
				label: 'Mobile Number',
				name: 'mobileNumber',
				type: 'number',
				required: true,
				value: this.state.mobileNumber,
				placeholder: 'Enter your mobile number',
			},
			{
				label: 'Roll Number',
				name: 'roll',
				type: 'number',
				required: true,
				value: this.state.roll,
				placeholder: 'Enter your IITK Roll Number',
			},
		];

		return (
			<>
				<CssBaseline />
				<div className={classes.appbar}></div>

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				<Container maxWidth='sm' className={classes.container}>
					<Typography variant='h4' align='center' className={classes.mainHeader} gutterBottom>
						SignUp
					</Typography>

					{candidateDetails.map((item, index) => (
						<TextField
							name={item.name}
							value={item.value}
							label={item.label}
							type={item.type}
							required={item.required}
							placeholder={item.placeholder}
							onChange={this.onChange}
							variant='outlined'
							className={classes.inputField}
						/>
					))}

					<FormControl variant='outlined' required={true} className={classes.inputField}>
						<InputLabel htmlFor='outlined-age-native-simple'>Select your Role</InputLabel>
						<Select
							native
							value={this.state.role}
							onChange={this.onChange}
							label='Select your Role'
							disabled={true}
							inputProps={{
								name: 'role',
								id: 'outlined-age-native-simple',
							}}
						>
							<option aria-label='None' value='' />
							<option value='Candidate'>Candidate</option>
							<option value='Proposer'>Proposer</option>
							<option value='Seconder'>Seconder</option>
							<option value='Campaigner'>Campaigner</option>
						</Select>
					</FormControl>

					<FormControl variant='outlined' required={true} className={classes.inputField}>
						<InputLabel htmlFor='outlined-age-native-simple'>For the Post of (*If you are candidate)</InputLabel>
						<Select
							native
							value={this.state.post}
							onChange={this.onChange}
							label='For the Post of'
							inputProps={{
								name: 'post',
								id: 'outlined-age-native-simple',
							}}
						>
							<option aria-label='None' value='' />
							{postsAvailaible.map((post, index) => (
								<option key={index} value={post[0]}>
									{post[1]}
								</option>
							))}
						</Select>
					</FormControl>

					<div className={classes.wrapper}>
						<Button variant='contained' color='primary' className={classes.buttonClassname} fullWidth disabled={this.state.disabled} onClick={this.signup}>
							SignUp
						</Button>
						{this.state.disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
					</div>
				</Container>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Signup);
